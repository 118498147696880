var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "content" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "form-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "form alt" },
          [
            _vm.message
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger mb-4",
                    attrs: { role: "alert" }
                  },
                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                )
              : _vm._e(),
            _c("p", [
              _vm._v(
                " Welcome, Please sign up to the DRS app. Your request will be sent the our customer care team who will authorise your account shortly "
              )
            ]),
            _c("ValidationObserver", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "needs-validation",
                          attrs: { novalidate: "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "form-row form-group" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "First Name",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(v) {
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.user.first_name,
                                                  expression: "user.first_name"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-lg",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: {
                                                type: "text",
                                                placeholder: "First Name"
                                              },
                                              domProps: {
                                                value: _vm.user.first_name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.user,
                                                    "first_name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invalid-feedback text-left"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(v.errors[0]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Surname", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(v) {
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.user.last_name,
                                                  expression: "user.last_name"
                                                }
                                              ],
                                              staticClass:
                                                "form-control form-control-lg",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: {
                                                type: "text",
                                                placeholder: "Surname"
                                              },
                                              domProps: {
                                                value: _vm.user.last_name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.user,
                                                    "last_name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invalid-feedback text-left"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(v.errors[0]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "E-mail",
                                  rules: "required|email"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.email,
                                                expression: "user.email"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "email",
                                              placeholder: "Email"
                                            },
                                            domProps: { value: _vm.user.email },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Password",
                                  rules: "required|confirmed:confirmation"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.password,
                                                expression: "user.password"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "password",
                                              placeholder: "Password"
                                            },
                                            domProps: {
                                              value: _vm.user.password
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "password",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-text text-left",
                                              attrs: { id: "passwordHelpBlock" }
                                            },
                                            [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    "Your password can’t be too similar to your other personal information."
                                                  )
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    "Your password must contain at least 8 characters."
                                                  )
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    "Your password can’t be a commonly used password."
                                                  )
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    "Your password can’t be entirely numeric."
                                                  )
                                                ])
                                              ])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Password Confirmation",
                                  rules: "required",
                                  vid: "confirmation"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.re_password,
                                                expression: "user.re_password"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "password",
                                              placeholder: "Confirm Password"
                                            },
                                            domProps: {
                                              value: _vm.user.re_password
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "re_password",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Employee number",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.employee_number,
                                                expression:
                                                  "user.employee_number"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "Employee number"
                                            },
                                            domProps: {
                                              value: _vm.user.employee_number
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "employee_number",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Profile Type",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.user.profile_type,
                                                  expression:
                                                    "user.profile_type"
                                                }
                                              ],
                                              staticClass:
                                                "custom-select form-control form-control-lg",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: {
                                                "aria-label":
                                                  "Default select example"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.user,
                                                    "profile_type",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [
                                                  _vm._v(
                                                    "Select your profile type"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                {
                                                  attrs: { value: "engineer" }
                                                },
                                                [_vm._v("Engineer")]
                                              ),
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "contact_center"
                                                  }
                                                },
                                                [_vm._v("Contact Centre")]
                                              ),
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "back_office"
                                                  }
                                                },
                                                [_vm._v("Back Office")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "finance" } },
                                                [_vm._v("Finance")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "sales" } },
                                                [_vm._v("Sales")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-check" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "Terms & Conditions",
                                      rules: { required: { allowFalse: false } }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(v) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.user.terms_accepted,
                                                    expression:
                                                      "user.terms_accepted"
                                                  }
                                                ],
                                                staticClass: "form-check-input",
                                                class: {
                                                  "is-invalid":
                                                    v.invalid && v.validated
                                                },
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "aggre-with-terms"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.user.terms_accepted
                                                  )
                                                    ? _vm._i(
                                                        _vm.user.terms_accepted,
                                                        null
                                                      ) > -1
                                                    : _vm.user.terms_accepted
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.user.terms_accepted,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.user,
                                                            "terms_accepted",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.user,
                                                            "terms_accepted",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "terms_accepted",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-check-label",
                                                  attrs: {
                                                    for: "aggre-with-terms"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "I agree with terms & conditions"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback text-left"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(v.errors[0]) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary alt",
                                  attrs: {
                                    type: "submit",
                                    disabled: invalid || _vm.loading
                                  }
                                },
                                [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading"
                                      }
                                    ],
                                    staticClass: "spinner-grow spinner-grow-sm",
                                    staticStyle: {
                                      width: "1.0625rem",
                                      height: "1.0625rem"
                                    },
                                    attrs: {
                                      role: "status",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _vm._v(" Sign up ")
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("footer", [
          _c(
            "p",
            [
              _vm._v(" Already have an account? "),
              _c(
                "router-link",
                {
                  staticClass: "text-primary-light text-underline",
                  attrs: { to: "/login" }
                },
                [_vm._v("Sign in")]
              )
            ],
            1
          ),
          _vm._m(1)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "logo alt" }, [
        _c("img", {
          attrs: { src: require("./../../assets/images/logo.png"), alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { staticClass: "mr-3", attrs: { href: "#" } }, [
        _vm._v("Term of use")
      ]),
      _c("a", { attrs: { href: "#" } }, [_vm._v("Privacy Policy")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }