<template>
  <main class="content">
    <div class="container">
      <div class="form-block">
        <header>
          <div class="logo alt">
            <img src="./../../assets/images/logo.png" alt="" />
          </div>
        </header>
        <div class="form alt">
          <div v-if="message" class="alert alert-danger mb-4" role="alert">
            {{ message }}
          </div>
          <p>
            Welcome, Please sign up to the DRS app. Your request will be sent
            the our customer care team who will authorise your account shortly
          </p>
          <ValidationObserver v-slot="{ invalid }">
            <form class="needs-validation" @submit.prevent="submit" novalidate>
              <div class="form-row form-group">
                <div class="col">
                  <ValidationProvider name="First Name" rules="required" v-slot="v">
                    <input type="text" class="form-control form-control-lg" placeholder="First Name"
                      v-model="user.first_name" v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                    <div class="invalid-feedback text-left">
                      {{ v.errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col">
                  <ValidationProvider name="Surname" rules="required" v-slot="v">
                    <input type="text" class="form-control form-control-lg" placeholder="Surname"
                      v-model="user.last_name" v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                    <div class="invalid-feedback text-left">
                      {{ v.errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group">
                <ValidationProvider name="E-mail" rules="required|email" v-slot="v">
                  <input type="email" class="form-control form-control-lg" placeholder="Email" v-model="user.email"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="Password" rules="required|confirmed:confirmation" v-slot="v">
                  <input type="password" class="form-control form-control-lg" placeholder="Password"
                    v-model="user.password" v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                  <div id="passwordHelpBlock" class="form-text text-left">
                    <ul>
                      <li>Your password can’t be too similar to your other personal information.</li>
                      <li>Your password must contain at least 8 characters.</li>
                      <li>Your password can’t be a commonly used password.</li>
                      <li>Your password can’t be entirely numeric.</li>
                    </ul>
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="Password Confirmation" rules="required" v-slot="v" vid="confirmation">
                  <input type="password" class="form-control form-control-lg" placeholder="Confirm Password"
                    v-model="user.re_password" v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
               <div class="form-group">
                <ValidationProvider name="Employee number" rules="required" v-slot="v">
                  <input type="text" class="form-control form-control-lg" placeholder="Employee number" v-model="user.employee_number"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="Profile Type" rules="required" v-slot="v">
                  <select class="custom-select form-control form-control-lg" aria-label="Default select example" v-model="user.profile_type"  v-bind:class="{ 'is-invalid': v.invalid && v.validated }" >
                    <option value="">Select your profile type</option>
                    <option value="engineer">Engineer</option>
                    <option value="contact_center">Contact Centre</option>
                    <option value="back_office">Back Office</option>
                    <option value="finance">Finance</option>
                    <option value="sales">Sales</option>
                  </select>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="form-check">
                  <ValidationProvider name="Terms &amp; Conditions" :rules="{ required: { allowFalse: false } }"
                    v-slot="v">
                    <input type="checkbox" class="form-check-input" id="aggre-with-terms" v-model="user.terms_accepted"
                      v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                    <label class="form-check-label" for="aggre-with-terms">I agree with terms &amp; conditions</label>
                    <div class="invalid-feedback text-left">
                      {{ v.errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
                <button type="submit" class="btn btn-primary alt" :disabled="invalid || loading">
                  <span v-show="loading" class="spinner-grow spinner-grow-sm"
                    style="width: 1.0625rem; height: 1.0625rem" role="status" aria-hidden="true"></span>
                  Sign up
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <footer>
          <p>
            Already have an account?
            <router-link to="/login" class="text-primary-light text-underline">Sign in</router-link>
          </p>
          <p>
            <a href="#" class="mr-3">Term of use</a><a href="#">Privacy Policy</a>
          </p>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import AuthService from "@/services/auth.service";
import _ from "lodash";

export default {
  name: "Signup",
  components: {},
  props: {},
  data: function () {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        re_password: "",
        employee_number: "",
        profile_type: "",
        terms_accepted: false,
      },
      message: "",
      loading: false,
    };
  },
  methods: {
    submit: function () {
      this.loading = true;
      AuthService.register(this.user).then(
        () => {
          this.$router.push("/signup-successful");
        },
        (error) => {
          this.loading = false;
          if (error.response) {
            _.forEach(error.response.data, (value, key) => {
              this.message = key + ": " + value[0];
            });
          } else {
            this.message = error.message || error.toString();
          }
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>